import React, { useState, useEffect, useRef, useCallback} from 'react'
import styled from "styled-components"
import media, {sizes} from './media'

const Navbar = styled.ul`
    display:flex;
    align-items:center;
    justify-content:center;
    background:linear-gradient(0deg, rgba(34,24,28,0.8) 0%, rgba(34,24,28,1) 100%);
    font-family:Arial, Helvetica, sans-serif;
    text-shadow: 0px 2px 2px rgba(13,3,15,1);
    
    ${media.phone`
        display:flex; 
        flex-flow:column; 
        padding:10px 0; 
        background:linear-gradient(0deg, rgba(34,26,36,0.9) 0%, rgba(13,3,15,1) 75%);
    `}
`
const NavItems = styled.li`
    display:inline-flex;
    justify-content:space-between;
    color:${props => props.selected ? '#FFF8C2' : '#C7A4C4'};
    cursor:pointer;
    white-space:nowrap;

    &:hover{
        color:#FFF8C2;
    }
    ${media.desktop`margin:10px 1.5%; font-size:larger;`}
    ${media.laptop`margin: 10px 1.5%; font-size:large;`}
    ${media.tablet`margin:10px 1%; font-size:medium;`}
    ${media.phone`margin:15px 0px; font-size:large;`}
`
const Hoverlay = styled.span`
    &:hover {
        color:#FFF;
    }
`

let onceTop = '', onceSelected = ''
//let onceMiddle = ''

export default props => {

    const {
        setLoadDynamicAreas, 
        scrollRefs, 
        setPhoneBtnVis, 
        setNavOpen, 
        setScrollFlag, 
        Link
    } = props

    const [selected, setSelected] = useState(0)

    const rPhoneNav = useRef(null)

    const onScroll = useCallback(
        refsTop => {
            let y = window.pageYOffset, 
            detectionGap = 100      
            
            refsTop.forEach((item, i) => {
                
                let selectedFlag = item.elemTop + item.docTop -20, 
                topFlag = item.elemTop + item.docTop -300 
                //midFlag = topFlag + (item.gbcr.height/2)
                
                if(onceSelected !== item.name && y < selectedFlag + detectionGap && y > selectedFlag){
                    setSelected(i)
                    onceSelected = item.name
                }
                else if(onceTop !== item.name && y < topFlag + detectionGap && y > topFlag){
                    onceTop = item.name
                    //onceMiddle = ''
                    setScrollFlag({name:item.name, elemTop:item.elemTop, indx:i, key:'top'})
                }
                /*else if(onceMiddle !== item.name && y < midFlag + detectionGap && y > midFlag){
                    onceMiddle = item.name
                    onceTop = ''
                    setScrollFlag({name:item.name, gbcr:item.gbcr, indx:i, key:'middle'})
                }*/
            })
        },[setScrollFlag])
    
    let callEffectOne = useCallback(
        () => {
            window.onscroll = () => onScroll(
                scrollRefs.map(item => ({
                    name:item.name,
                    elemTop:item.ref.current.getBoundingClientRect().top, 
                    docTop:document.documentElement.scrollTop
                }))
            )
        },[onScroll, scrollRefs])

    let callEffectTwo = useCallback(
        () => {
            let navChanged = mq => {
                setPhoneBtnVis(!mq.matches)
                rPhoneNav.current = !mq.matches
                //rPhoneNav.current && setNavOpen(false)
            }
            window.onresize = () => {
                window.onscroll = () => onScroll(
                    scrollRefs.map(item => ({
                        name:item.name, 
                        elemTop:item.ref.current.getBoundingClientRect().top, 
                        docTop:document.documentElement.scrollTop
                    }))
                )
            }
            let mq = window.matchMedia(`(min-width: ${sizes.phone}px)`)
            mq.addListener(navChanged)
            navChanged(mq)
        },[onScroll, scrollRefs, setPhoneBtnVis])

    useEffect(() => {
        callEffectOne()
    },[callEffectOne])

    useEffect(() => {
        callEffectTwo()
    },[callEffectTwo])

    const scrollTo = (ref, indx) => e => {
        ref.current.scrollIntoView({ behavior: 'smooth' })
        setSelected(indx)
        rPhoneNav.current && setNavOpen(false)
    }

    const openOverlays = area => e => {
        e.preventDefault()
        setLoadDynamicAreas(state => [!state[0], area])
    }

    return (
        <Navbar>
            {scrollRefs.map((item, i) => (
                <React.Fragment key={`${i}shop`}>
                    <NavItems
                    onClick={scrollTo(item.ref, i)}
                    selected={i === selected ? true : false}
                    >
                        {item.name}
                    </NavItems>
                    {i === scrollRefs.length-1 &&
                            <>
                                <NavItems onClick={openOverlays('shop')}>
                                    <Link to='/shop'><Hoverlay>SHOP</Hoverlay></Link>
                                </NavItems>                        
                                <NavItems onClick={openOverlays('gallery')}>
                                    <Link to='/gallery'><Hoverlay>GALLERY</Hoverlay></Link>
                                </NavItems>
                                <NavItems onClick={openOverlays('docs')}>
                                    <Link to='/docs'><Hoverlay>DOCS</Hoverlay></Link>
                                </NavItems>
                        </>
                    }
                </React.Fragment>
            )
            )}
        </Navbar>
    )
}



