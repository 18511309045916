import {
    Stitch,
    RemoteMongoClient,
    AnonymousCredential //,BSON
} from 'mongodb-stitch-browser-sdk'

const client = Stitch.initializeDefaultAppClient('nimfmstitchapp-swhub')
const db = client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db('nimfm')
const dbLogin = client.auth.loginWithCredential(new AnonymousCredential())

export default {client, db, dbLogin}