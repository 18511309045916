import React, {useEffect} from 'react'
import { useTrail, config} from 'react-spring'
import { BodyTwo, SnakeCont, Snake } from './bodyTwoStyles'
import {HeadlineTrail} from './springAnimations'

let hline = [`Jinggiwahla,`, `Bugalbeh`, 'and Welcome']

export default ({
    generalColl, 
    scrollFlag
}) => {

    const [intro, setIntro] = useTrail(hline.length, () => ({opacity: 0, x: 20, height: 0}))
    
    useEffect(() => {
        scrollFlag.once && setIntro({opacity: 1, x: 0, height: 80, config:config.default})
    }, [scrollFlag, setIntro])

    return (
        <>
            <BodyTwo>
                <HeadlineTrail intro={intro} hline={hline} />
                {generalColl.length > 0 && generalColl[0].description}
            </BodyTwo>
            <SnakeCont><Snake src={'/assets/two/snake.svg'} /></SnakeCont>
        </>
    )
}




