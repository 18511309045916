import React, {useState, useEffect, useCallback, useRef} from 'react'
import { animated, useSpring } from 'react-spring'
import Podcasts from './Podcasts'
import {Main, Txt, Circle, ImageCont, ShowLink} from './profileStyles'
import Loader from '../Loader'

export default ({
                mongo,
                host,
                setOverlayBgs,
                nextPath,
                prevPath,
                currPath,
                setNextPrev,
                nextPrev,
                setPlayerUrl,
                setPlayerPause,
                playerPause,
                audioStream
                }) => {

    const [profileData, setProfileData] = useState({})

    let podcastTitleArr = useRef([])
    let podcastUrlArr =  useRef([])
    let linkDescArr = useRef([])
    let linkUrlArr =  useRef([])

    const [imgSpring, setImgSpring] = useSpring(() => ({from:{opacity: 0, transform:`translateX(${nextPrev.lr ? '-100%' : '100%'})`}, reset:true}) )

    let callEffect = useCallback( 
        () => {
            let query = { "title": host.title }
            let options = { "limit": 1 }

            let imgLoad = () => {

                let addImageProcess = src => {
                    return new Promise((resolve, reject) => {
                        let img = new Image()
                        img.onload = () => resolve((() => { setImgSpring({to:{opacity: 1, transform:'translateX(0)'}, reset:true})})() )
                        img.onerror = reject
                        img.src = src
                    })
                }
    
                profileData.mastHead !== '' && (async () => await addImageProcess(profileData.mastHead))()
            }
    
            !profileData.title 
                ?   mongo.dbLogin
                    .then(() => mongo.db
                        .collection('hosts')
                        .findOne(query, options)
                    )
                    .then(doc => {
                        if(doc.podcastTitle) podcastTitleArr.current = doc.podcastTitle.split(',')
                        if(doc.podcastUrl){
                            podcastUrlArr.current = doc.podcastUrl.replace(/[\n\r]+/g, '').split(',')
                            podcastUrlArr.current = podcastUrlArr.current.map(val => val.trim())
                        }

                        if(doc.linkDesc) linkDescArr.current = doc.linkDesc.split(',')
                        if(doc.linkUrl) linkUrlArr.current = doc.linkUrl.replace(/[\n\r]+/g, '').split(',')
                        linkUrlArr.current = linkUrlArr.current.map(val => val.trim())
                        setProfileData(doc)
                        setOverlayBgs(doc.bgImage)
                    })
                    .catch(err => console.error(err))
                :   profileData.mastHead ? imgLoad() : setImgSpring({to:{opacity: 1, transform:'translateX(0)'}, reset:true})


        }, [setImgSpring, profileData.mastHead, mongo.db, mongo.dbLogin, host, profileData.title, setOverlayBgs])
    

    useEffect(() => {callEffect()}, [callEffect])

    useEffect(() => {setNextPrev({next:nextPath, prev:prevPath})}, [nextPath, prevPath, setNextPrev])

    return (
        <>
            {profileData.title ? 
                <animated.div style={imgSpring}>
                    <Main>
                        <div className={'body-copy'}>On {host.day.charAt(0).toUpperCase() + host.day.slice(1)}, starting at {host.time}.00</div>
                        <div className={'headline'}>{profileData.title}</div>
                        {
                            profileData.mastHead !== '' &&
                                <Circle style={{marginTop:'10px',marginBottom:'25px', ...imgSpring}} url={profileData.mastHead} />
                        }
                        {profileData.imgOne && <ImageCont url={profileData.imgOne}/>}
                        {profileData.audOne &&  <audio controls style={{margin:'25px'}}>
                                        <source src={profileData.audOne} type="audio/mpeg" />
                                    </audio>}
                        {profileData.parOne && <Txt className={'body-copy'}>{profileData.parOne}</Txt>}
                        {
                            podcastUrlArr.current.length > 0 &&
                                <Podcasts
                                setPlayerUrl={setPlayerUrl}
                                setPlayerPause={setPlayerPause}
                                playerPause={playerPause}
                                titles={podcastTitleArr.current}
                                urls={podcastUrlArr.current}
                                audioStream={audioStream.current}
                                currPath={currPath}
                                />
                        }
                        {profileData.imgTwo && <ImageCont url={profileData.imgTwo}/>}
                        {profileData.audTwo &&  <audio controls style={{margin:'25px'}}>
                                        <source src={profileData.audTwo} type="audio/mpeg" />
                                    </audio>}
                        {profileData.parTwo && <Txt className={'body-copy'}>{profileData.parTwo}</Txt>}
                        {linkDescArr.current.length > 0 && <Txt className={'body-copy'}>Recommended links from the show:</Txt>}
                        {linkUrlArr.current.length > 0 && linkDescArr.current.map((desc, i) => (<ShowLink key={`${i}links`} href={linkUrlArr.current[i]} target="_blank" rel="noopener noreferrer">{desc}</ShowLink>))}
                    </Main>
                </animated.div>                    
            : <Loader />}
        </>
    )
}