import styled from 'styled-components'
//import media from '../media'

export const Wrapper = styled.ul`
    width:100%;
    margin-top:20px;
    max-width:800px;
    max-height:500px;
    display:flex;
    flex-direction:column;
`
export const Item = styled.li`
    position:relative;
    width:100%;
    height:60px;
    padding:8px;
    background:rgba(13,3,15,0.5);
    margin-top:3px;
    border-radius: 30px;
`
export const ScrollBox = styled.div`
    overflow-y:auto;
`
export const Title = styled.span`
    width:100%;
    height:50px;
    border-radius: 5px;
    text-align:center;
    font-size:1.7rem;
    padding:5px;
    text-shadow: 0px 2px 2px rgba(13,3,15,1);
    background: linear-gradient(180deg, rgba(34,26,36,0) 0%, rgba(13,3,15,0.5) 100%);
    z-index:1;
`
export const SubTitle = styled.span`
    width:100%;
    height:50px;
    display:flex;
    align-items:center;
    justify-content:center;
    margin-top:3px;
    border-radius:5px;
    font-size:0.9rem;
    padding:5px;
    text-shadow: 0px 2px 2px rgba(13,3,15,1);
    background: linear-gradient(0deg, rgba(34,26,36,0) 0%, rgba(13,3,15,0.5) 100%);
    z-index:1;
`
export const ContUl = styled.ul`
    display:flex;
    align-items:center;
`
export const ItemTitle = styled.li`
    flex-grow:1;
    font-size:1.2rem;
    color:white;
    margin-left:10px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`