import React, {useState} from 'react'
import styled from 'styled-components'
import {useSpring, animated} from 'react-spring'

const StarCont = styled.div`
    position:absolute; 
	top:0;
	left:0;
	right:0;
	bottom:0;
	overflow:hidden; 
`
const ShootStar = styled.div`
    position:relative;
    width:200px;
    transform:translateX(${props => props.posx}px) rotate(${props => props.rot}deg);
`
const ShootStarSvg = styled(animated.svg).attrs(props => ({
    xmlns:"http://www.w3.org/2000/svg",
    xmlnsXlink:"http://www.w3.org/1999/xlink",
    width:'99.7',
    height:'1.23',
    viewBox: "0 0 26.4 .327"
}))`
    position:absolute;
    top:0;
	left:0;
`

let rand = (min, max) => (Math.floor(Math.random() * (max - min + 1) + min))
let winWH = [window.innerWidth, window.innerHeight]

export default props => {
    
    const [newStar, setNewStar] = useState(false)
    
    const posx = rand(0, winWH[0])
    let rotdirLeft = posx > winWH[0]/2 ? true : false
    const rot = rotdirLeft ? rand(95, 160) : rand(5, 70)

    const [moveStar, setMoveStar] = useSpring(() => ({
        transform:`translateX(0px)`, 
        immediate:true
    }))

    setMoveStar({
        transform:`translateX( ${winWH[0]+winWH[1]}px )`,
        config:{ mass: 1, tension: 13, friction: 13},
        immediate:false,
        onRest: () => resetMoveStar()
    })

    const resetMoveStar = () => {
        setMoveStar({
            transform:`translateX( 0px )`,
            immediate:true
        })
        setTimeout(() => setNewStar(!newStar), rand(1000,3000)) 
    }
          
    return <StarCont>
        <ShootStar posx={posx} rot={rot}>
            <ShootStarSvg  style={moveStar}>
                <defs>
                    <linearGradient id="stara" x1="3.02" x2="20.1" y1="3.83" y2="3.83" gradientTransform="matrix(1.52 0 0 1 -3 -1.49)" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#edf9ed" stopOpacity="0" offset="0"/>
                        <stop stopColor="#fff" offset="1"/>
                    </linearGradient>
                </defs>
                <g transform="translate(-1.44 -2.18)">
                    <path d="m1.61 2.34h26" fill="none" stroke="url(#stara)" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".327"/>
                </g>
            </ShootStarSvg>
        </ShootStar>
    </StarCont>
}