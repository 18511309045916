import React, {useEffect} from 'react'
import {useSpring} from 'react-spring'
import { BodyOverflow, Overlay, Overflow, Close, PhoneCloseMobile, BgImage, PrevNextNav } from './overlayStyles'
import { FiX } from 'react-icons/fi'
import CircleBtn from '../buttons/CircleBtn'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
 
export default ({open, setOpen, isZoom, mediaPhone, topNav, iconColor, bgColor, disableClose, bgImage, bgRepeat, children, useHistory, nextPrev, setNextPrev, showNextPrevBtns, Link}) => {

    const history = useHistory()

    const close = e => {
        e.preventDefault() 
        if(disableClose) return
        setSpringOpenClose({opacity:'0', transform:'scale(0.9) translate3d(0px,500px,0px)', onRest: () => isZoom ? setOpen(false) : history.push('/')})
    }

    const isDirection = lr=> e => {
        e.preventDefault() 
        setNextPrev(state => ({...state, lr:lr}))
    }

    const [springOpenClose, setSpringOpenClose] = useSpring(() => ({opacity:'0', transform:'scale(0.9) translate3d(0px,500px,0px)'}))

    useEffect(() => {
        setSpringOpenClose({opacity:'1', transform:'scale(1) translate3d(0px,0px,0px)'})
    }, [setSpringOpenClose])
    
    return (
        <>
            <Overlay style={springOpenClose}>
                {bgImage && <BgImage url={bgImage} bgRepeat={bgRepeat} />}
                {!isZoom && <BodyOverflow bodyOverflow={!open} />}
                {!mediaPhone && <Close onClick={close} />}
                <Overflow topNav={topNav} bgColor={bgColor}>
                    {children}
                    {showNextPrevBtns &&
                        <>
                            <PrevNextNav l={true} onClick={isDirection(true)}>
                                <Link to={`/profile/${nextPrev.prev}`}>
                                    <CircleBtn  size={'60px'} bg={'rgba(13,3,15,0.3);'} icon={<FiChevronLeft color={'white'} size={32} />} />
                                </Link>
                            </PrevNextNav>
                            <PrevNextNav onClick={isDirection(false)}>
                                <Link to={`/profile/${nextPrev.next}`}>
                                    <CircleBtn  size={'60px'} bg={'rgba(13,3,15,0.3);'} icon={<FiChevronRight color={'white'} size={32} />} />
                                </Link>
                            </PrevNextNav>
                        </>
                    }
                </Overflow>                
                <PhoneCloseMobile topNav={topNav} onClick={close} ><FiX size={36} color={iconColor} /></PhoneCloseMobile>                
            </Overlay>
        </>
    )
}



