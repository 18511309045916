import styled from 'styled-components'
import media from '../media'
import { animated } from 'react-spring'

export const Main = styled.div`
    position:relative;
    flex-direction:column;
    display:flex;
    align-items:center;
    justify-content:center;
    padding:0 30px;
`
export const Doc = styled(animated.a)`
    display:flex;
    width:100%;
    max-width:700px;
    cursor: pointer;

    &:hover {
        background:rgba(13,3,15,0.5);
        color:white;
    }
`
export const DocName = styled.span`
    position:relative;
    display:flex;
    flex-grow:2;
    width:100%;
    background:rgba(199,164,196,0.1);
    padding:20px;
    margin-bottom:1px;
    font-size:1.5rem;

    ${media.phone`
        font-size:1rem;
        padding:10px;
        margin-bottom:10px;
        `
    }
`
export const ActionLabel = styled.span`
    position:relative;
    display:flex;
    white-space: nowrap;
    align-items:center;
    justify-content:center;
    background:rgba(199,164,196,0.3);
    font-family:Arial, Helvetica, sans-serif;
    padding:0 20px;
    font-size:0.9rem;
    margin-bottom:1px;

    &:before{
    content: "";
    position: absolute;
    right: -20px;
    top:0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 20px solid rgba(199,164,196,0.3);
    border-top: 32px solid transparent;
    border-bottom: 32px solid transparent;
    }

    ${media.phone`
        font-size:0.7rem;
        padding:10px;
        margin-bottom:10px;

        &:before{
        content: none;
    }
        `
    }
`