import React from 'react'
import styled from "styled-components"
import media from '../media'
import { FaPlay, FaPause} from 'react-icons/fa'

const PlayBtnCont = styled.li`
	position: ${props => props.small ? 'relative' : 'absolute'};
	z-index:2;
	background: #c7a4c43b;
    display: flex;
    flex-grow:0;
    min-width:45px;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	cursor: pointer;
	box-shadow: 1px 1px 3px rgba(13,3,15, 0.7);

    ${props => props.small 
        ? media.desktop` width: 55px; height: 55px;` 
        : media.desktop` top: 340px; width: 90px; height: 90px;`
    }
    ${props => props.small 
        ? media.laptop` width: 45px; height: 45px;`
        : media.laptop` top: 220px; width: 70px; height: 70px;`
    }
`
const PlayBtn = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	color: #b595b2;

	${PlayBtnCont}:hover & {
		color: #fff8c2;
	}
`

export default ({
        setPlayerUrl, 
        urls, 
        setPlayerPause, 
        playerPause,
        small,
        audioStream
    }) => {

    let player = e => {
        e && e.preventDefault()
        urls.url === audioStream.src
        ? setPlayerPause(state => ({pauseplay:!state.pauseplay, btn:true}))
        : setPlayerUrl(urls)
    }

    //console.log('PlayBtn called',playerPause.pauseplay)

    return(
        <PlayBtnCont onClick={player} small={small}>
            {
                playerPause.pauseplay && urls.url === audioStream.src
                ?   <PlayBtn>
                        <FaPause size={small ? 25 : 40} color={'#FFF8C2'} />
                    </PlayBtn>
                :   <PlayBtn style={{marginLeft: small ? '5px':'9px'}}>
                        <FaPlay size={small ? 25 : 40}  />
                    </PlayBtn>
            }
        </PlayBtnCont>
    )
}