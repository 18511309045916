import styled from "styled-components"
import media from './media'

export const Center = styled.ul`
	position: absolute;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;

	${media.desktop` top: 10vh;`}
	${media.laptop` top: 15vh;`}
`
export const RainbowCont = styled.li`
	//overflow: hidden;
	margin-top:-50px;

	${media.desktop` width: 400px; height: 161px;`}
	${media.laptop` width: 320px; height: 129px;`}
`

export const RadioCont = styled.div`
	position:absolute;
	right:0;
	bottom:15px;
	left:0;
	display: flex;
	align-items: baseline;
	justify-content: flex-end;
	font-family:Arial, Helvetica, sans-serif;
	cursor: pointer;

    ${media.phone`flex-direction:column; align-items: center;`}
`
export const RadioTxt = styled.span`
  	font-family: 'Limelight', cursive;
	font-size: 1.8rem;
	margin:0 10px;
	color:#fff;

    ${media.phone`font-size: 1.5rem;`}
`
export const Broadcast = styled.div`
	display: flex;
	align-items: baseline;
	justify-content: center;
	color:#fff8c2;
	font-size:0.8rem;
	padding-left:10px;
	margin-right:15px;

    ${media.phone`flex-flow:wrap-reverse; margin:0 0 100px 0;`}
`
