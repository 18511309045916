import React, {useEffect} from 'react'
import { useTrail, config } from 'react-spring'
import { Main, BodyFour, Spirits, HeaderOne, Facebook, AudioLink, BtmBtns } from './bodyFourStyles'
import { FaFacebook, FaLink } from 'react-icons/fa'
import {HeadlineTrail} from './springAnimations'

let hline = [`Contact `, `Us`]

export default ({scrollFlag}) => {

    const [intro, setIntro] = useTrail(hline.length, () => ({opacity: 0, x: 20, height: 0}))

    useEffect(() => {
        scrollFlag.once && setIntro({opacity: 1, x: 0, height: 80, config:config.default})
    }, [scrollFlag, setIntro])

    return (
        <Main>
            <BodyFour className={'body-copy'}>
            <HeadlineTrail intro={intro} hline={hline} />
            <HeaderOne>"NimFM is always interested to hear from individuals who would like to become radio presenters."</HeaderOne><br/>
                <p>If you have an idea for your own show, call us on (02) 6689 0279, send an
                email to <a href="mailto:nimbinradiomedia@gmail.com?subject=Hi NimFm!">nimbinradiomedia@gmail.com</a>, or just drop in.</p><br/>
                <p>Full training is available.</p>
            </BodyFour >
            <Spirits src={'/assets/four/musicSpirits_small.svg'} />
            <BtmBtns>
                <Facebook
                href={'https://www.facebook.com/Nimbin-Community-Radio-NIM-fm-1023-111024132319428/'} 
                target="_blank" 
                rel="noopener noreferrer"
                >
                    <FaFacebook size={36} color={'#FFF'} /> <span style={{marginLeft:'15px'}}>Facebook</span>
                </Facebook>
                <AudioLink
                href={'http://uk5.internet-radio.com:8055/'} 
                target="_blank" 
                rel="noopener noreferrer"
                >
                    <FaLink  size={36} color={'#FFF'} /> <span style={{marginLeft:'10px'}}>Audio stream link</span>
                </AudioLink>
            </BtmBtns>
        </Main>
    )
}



