import React from "react";
import styled from "styled-components";
import { useSprings, animated } from "react-spring";
import media from "./media";


const Rainbow = styled.svg.attrs(props => ({
	xmlns: "http://www.w3.org/2000/svg",
	xmlnsXlink: "http://www.w3.org/1999/xlink",
	viewBox: `0 0 200 200`
}))`
	
    ${media.desktop` margin-top: 50px;`}
	${media.laptop` margin-top: 0;`}
`

const Circle = styled(animated.circle).attrs(props => ({
	cx: 100,
	cy: 100,
	r: props.rad
}))`
    fill: none; 
    stroke: url(${props => props.gradient}); 
    stroke-width: 5px;
    transform-origin: center;
`

const rainbowColors = [
	"rgba(255, 0, 0, 1)",
	"rgba(255, 128, 0, 1)",
	"rgba(255, 255, 0, 1)",
	"rgba(0, 255, 0, 1)",
	"rgba(0, 0, 255, 1)",
	"rgba(76, 0, 130, 1)",
	"rgba(148, 0, 211, 1)"
]

let rand = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)
let interval

export default ({ pause }) => {

	const [springs, set, stop] = useSprings(7, index => ({
			marginTop:"-20px",
			transform: "rotate(-180deg)",
			immediate: true
	}))

	const spinRainbow = () => {
		set(index => ({
			transform: `rotate(${rand(3600, 7200)}deg)`,
			immediate: false,
			config: { mass: rand(60000, 80000), tension: rand(170, 280), friction: rand(50, 220) }
		}))

		clearInterval(interval)
		interval = window.setInterval(() => {
			set(index => ({
				transform: `rotate(${rand(3600, 7200)}deg)`,
				immediate: false,
				config: { mass: rand(60000, 80000), tension: rand(170, 280), friction: rand(50, 220) }
			}))
		}, rand(80000, 100000))
	}
		
	!pause && clearInterval(interval)

	pause
		? spinRainbow()
		: set(index => ({
			transform: `rotate(0deg)`,
			immediate: false,
			config: { mass: 10, tension: rand(170, 280), friction: rand(50, 220) }
		}))

  return (<>
			<Rainbow>
				<defs>
    				<linearGradient id="fadeGrad" y1="1" y2="0.35" x2="0" x1="0">
						<stop offset="0.8" stopColor="white" stopOpacity="0"/>
						<stop offset="1" stopColor="white" stopOpacity="0.8"/>
					</linearGradient>

					<mask id="fade" maskContentUnits="objectBoundingBox">
						<rect width="1" height="1" fill="url(#fadeGrad)"/>
					</mask>

					{rainbowColors.map((color, i) =>
					<linearGradient
						key={`grad${i}`}
						id={`gradient-${i}`}
						gradientUnits="userSpaceOnUse"
						x1="100"
						y1={5 + 5 * i}
						x2="100"
						y2="195"
						gradientTransform="matrix(0.5, 0, 0, 0.5, 48, 2)"
					>
						<stop offset="0" style={{ stopColor: color }} />
						<stop offset="1" style={{ stopColor: "rgba(42, 42, 42, 0)" }} />
					</linearGradient>
					)}
				</defs>

				<g mask="url(#fade)" style={{transform:"scale(1.08) translateX(-10px)"}}>
					<circle cx="90" cy="90" r="95" style={{fillOpacity:"0"}}/>
					{springs.map((props, i) =>
						<Circle
						key={i}
						style={props}
						gradient={`#gradient-${i}`}
						rad={85 - 5 * i}
						
						/>
					)}
				</g>
				<g id="layer1"  fill="#fff" style={{transform:"scale(1.48) translate(-39px, 13px)"}} >
					<g id="nimfm">
						<path id="N0" d="m42.7 75 0.0576 1.92q0 0.499-0.269 0.768-0.269 0.269-0.653 0.269-0.365 0-0.614-0.25t-0.25-0.537q0-0.307 0.0384-0.557 0.0576-0.269 0.096-0.595 0.096-0.557 0.096-2.78v-23.9l20.9 16.5v-11.4q0-1.31-0.0768-1.86-0.096-0.902-0.096-1.27 0-0.96 0.825-0.96 0.326 0 0.614 0.23 0.307 0.23 0.307 0.672v0.672l-0.0768 2.78v24.1l-20.9-16.4z" />
						<path id="i0" d="m70 53.9q-1.98 0-3.09-0.883t-1.11-2.32q0-1.21 1.23-2.07 1.31-0.921 2.76-0.921 1.46 0 2.25 0.288 0.806 0.288 1.36 0.748 1.11 0.94 1.11 2.19 0 1.25-1.27 2.11-1.27 0.864-3.24 0.864zm-4.03 3.36h8.25v20.5h-8.25z" />
						<path id="m0" d="m98.9 62.6q0.0384 0.461 0.0384 0.921v14.3h-8.23v-18.6q-0.211-0.096-0.499-0.096h-0.614q-1.5 0-3.49 2.05-0.614 0.633-1.17 1.4v15.3h-8.25v-20.5h8.25v3.09q3.24-3.44 7.25-3.44 5.18 0 6.39 3.84 1.65-1.9 3.63-2.86 2-0.979 4.01-0.979 3.44 0 5.09 1.63 1.65 1.63 1.65 4.93v14.3h-8.23v-18.6q-0.173-0.0768-0.864-0.0768-0.691 0-1.29 0.269-0.576 0.25-1.19 0.71-1.34 0.979-2.48 2.53z" />
						<path id="F0" d="m131 60.3-0.787-0.0192q-0.422-0.0384-1.07-0.0384h-4.3v17.5h-9.79v-27.2h15.2l1.9-0.0576h0.345q1.44 0 1.44 0.844 0 0.326-0.269 0.614-0.25 0.269-0.557 0.269-0.499 0-0.96-0.096-0.883-0.192-2.3-0.192h-5.05v6.91h3.86t2.38-0.096q1.04 0 1.04 0.768 0 0.768-1.11 0.768z" />
						<path id="M0" d="m130 78.1q-0.365-0.0384-0.557-0.326-0.192-0.288-0.154-0.633 0.096-0.595 0.365-1.09 0.269-0.499 0.384-0.749 0.134-0.25 0.269-0.557l0.729-1.69 7.89-23.6 8.48 16.4 8.23-16.4 9.85 28.4h-10.3l-4.76-14.7-7.37 15.4-7.75-13.8-2.69 8.39q-0.633 1.4-0.902 2.38t-0.441 1.52q-0.384 1.21-1.27 1.13z" />
					</g>
					<g id="ftm">
						<path id="F1" d="m61.1 90c1.19 0.0423 1.27 1.9 0.0657 1.99-2.91 0.443-5.73 1.37-8.38 2.63-0.977 0.331-0.679 1.53-0.991 2.33-0.33 1.41-0.655 2.86-1.38 4.13-0.865 0.785-2.22-0.406-1.53-1.35 0.546-0.997 0.769-2.13 1.04-3.22-0.858 0.0202-1.26-1.25-0.641-1.81 0.662-0.668 1.48-1.1 1.32-2.19 0.336-2.61 0.593-5.25 0.448-7.88-0.854-0.559-0.136-1.42-0.0331-2.13 0.47-0.833 1.51-0.329 1.89 0.103 2.27-0.462 4.45-1.21 6.68-1.83 0.939-0.21 1.9-0.489 2.85-0.537 1.26 0.164 0.997 2.08-0.227 1.99-3.13 0.474-6.08 1.68-9.18 2.32 0.111 2.68-0.173 5.37-0.494 8.03 2.73-1.22 5.64-1.96 8.56-2.56z" />
						<path id="a1" d="m64 91.3c0.384-1.15 1.23-2.04 1.97-2.97 0.766-0.856 1.58-1.75 2.65-2.21 0.558-0.225 1.33 0.121 1.34 0.771-0.0486 0.363-0.0116 0.403 0.241 0.129 0.318-0.353 0.926-0.635 1.33-0.247 0.382 0.428 0.0604 1.03 0.0302 1.53-0.142 1.06-0.625 2.13-0.307 3.2 0.229 0.696 1.09 0.801 1.71 0.677 0.854-0.127 1.67-0.431 2.49-0.684 0.502-0.127 0.998 0.454 0.737 0.92-0.244 0.527-0.896 0.602-1.38 0.784-0.984 0.266-2.01 0.492-3.04 0.389-0.918-0.0998-1.77-0.803-1.89-1.75-0.131-0.716-0.0708-1.45 0.0291-2.17-0.9 1.1-1.61 2.35-2.61 3.38-0.667 0.481-1.52 0.784-2.35 0.686-0.586-0.0812-1.13-0.557-1.12-1.18-0.0286-0.426 0.024-0.859 0.17-1.26zm4.54-2.7c-0.407 0.0351-0.683-0.448-0.93 0.0644-0.671 0.746-1.39 1.47-1.9 2.34-0.221 0.399-0.378 0.834-0.452 1.28 0.946 0.124 1.69-0.643 2.21-1.33 0.618-0.804 1-1.76 1.62-2.56-0.176 0.0817-0.359 0.146-0.543 0.206z" />
						<path id="c1" d="m79.3 86.9c-0.29 0.321-0.718 0.0355-1.01 0.198-1.28 0.762-2.33 1.95-2.81 3.37-0.181 0.449-0.151 0.984 0.408 1.1 0.919 0.332 1.92 0.131 2.85-0.0737 0.98-0.245 1.93-0.591 2.87-0.943 0.364-0.146 0.838-0.261 1.14 0.0691 0.311 0.284 0.229 0.809-0.126 1.02-0.614 0.288-1.27 0.492-1.91 0.734-1.46 0.526-3.01 1.05-4.58 0.795-0.819-0.137-1.73-0.483-2.07-1.31-0.341-0.917 0.0108-1.91 0.423-2.74 0.639-1.2 1.59-2.23 2.69-3.03 0.506-0.347 1.08-0.627 1.69-0.7 0.506 0.0378 0.841 0.601 0.647 1.06-0.0548 0.157-0.135 0.303-0.22 0.445z" />
						<path id="e1" d="m88.1 83.2c0.491 0.309 0.48 0.998 0.391 1.5-0.325 1.09-1.26 1.91-2.27 2.38-0.861 0.401-1.77 0.674-2.69 0.917-0.466 0.715-1.01 1.49-0.994 2.38 0.0504 0.683 0.819 0.958 1.4 0.99 1.44 0.0786 2.84-0.365 4.22-0.755 0.882-0.272 1.75-0.596 2.59-0.984 0.415-0.148 0.864 0.151 0.923 0.579 0.079 0.456-0.377 0.752-0.759 0.859-2.16 0.854-4.42 1.58-6.76 1.62-0.887-0.0086-1.96-0.16-2.46-0.985-0.624-0.977-0.374-2.22 0.0141-3.24 0.678-1.62 1.75-3.1 3.1-4.22 0.752-0.565 1.6-1.14 2.58-1.17 0.247-0.0014 0.494 0.0424 0.725 0.13zm-1.11 1.37c-0.756 0.337-1.32 0.973-1.91 1.54 0.757-0.328 1.48-0.813 1.91-1.54z" />
						<path id="t1" d="m97 87.4c-0.507 0.15-0.988 0.415-1.51 0.482-0.685-0.0045-0.717-1.08-0.0448-1.17 0.559-0.228 1.13-0.414 1.71-0.599 0.065-1.37 0.488-2.68 0.717-4.02 0.0722-0.493-0.0492-1.11 0.332-1.51 0.417-0.387 1.12 0.133 0.926 0.582-0.14 1.47-0.504 2.9-0.709 4.36-0.119 0.275 0.0401 0.248 0.252 0.164 0.562-0.134 1.12-0.323 1.68-0.42 0.761-0.0388 0.722 1.11 0.0232 1.17-0.717 0.197-1.44 0.381-2.15 0.589-0.232 1.86-0.421 3.75-0.924 5.56-0.243 0.594-0.274 1.35-0.774 1.8-0.739 0.351-1.09-0.689-0.662-1.19 0.67-1.86 0.885-3.84 1.14-5.79z" />
						<path id="h1" d="m103 80.6c0.629-0.0459 0.744 0.663 0.662 1.14-0.0644 1.2-0.511 2.33-0.746 3.5-0.224 0.987-0.37 1.99-0.568 2.98 0.507-0.954 1.23-1.9 2.28-2.28 0.52-0.0939 1.07 0.0435 1.55 0.228 0.83 0.404 0.984 1.43 0.946 2.26-0.0402 1.26-0.381 2.52-1.04 3.6-0.553 0.463-1.32-0.295-0.879-0.852 0.562-0.896 0.613-2 0.622-3.03-0.0179-0.425-0.0765-1.13-0.633-1.14-0.789 0.226-1.35 0.935-1.72 1.63-0.739 1.29-1.13 2.75-1.94 4-0.269 0.483-1 1.11-1.45 0.471-0.219-0.549 0.135-1.12 0.255-1.66 0.504-1.26 0.708-2.61 0.951-3.94 0.262-1.16 0.46-2.34 0.653-3.51 0.133-1.01 0.188-2.06 0.624-3 0.0965-0.175 0.231-0.358 0.435-0.41z" />
						<path id="e2" d="m113 84.5c0.4 0.28 0.363 0.859 0.267 1.28-0.308 0.881-1.1 1.52-1.94 1.87-0.761 0.32-1.57 0.519-2.37 0.691-0.422 0.582-0.912 1.21-0.934 1.96 0.0144 0.577 0.649 0.839 1.14 0.891 1.19 0.125 2.37-0.183 3.52-0.448 0.771-0.195 1.53-0.436 2.27-0.735 0.355-0.108 0.721 0.162 0.753 0.525 0.0477 0.386-0.348 0.617-0.673 0.691-1.85 0.63-3.78 1.15-5.75 1.09-0.746-0.0437-1.64-0.215-2.03-0.93-0.485-0.847-0.223-1.88 0.145-2.72 0.637-1.34 1.6-2.53 2.78-3.42 0.656-0.444 1.4-0.892 2.22-0.877 0.208 0.0089 0.414 0.0559 0.604 0.139zm-0.993 1.11c-0.65 0.253-1.15 0.764-1.67 1.22 0.65-0.244 1.28-0.623 1.67-1.22z" />
						<path id="M1" d="m119 95.1c-0.168-3 0.36-5.97 0.912-8.9 0.186-1.21 0.502-2.39 0.619-3.61-0.787-0.691-0.119-1.96 0.931-1.78 0.769-0.0286 1.77 0.0787 2.2 0.961 0.881 1.27 1.02 2.89 1.97 4.12 0.492 0.825 1.06 1.63 1.84 2.2 1.21-0.322 1.56-1.7 2.13-2.66 1.12-2.28 2.18-4.63 3.86-6.57 0.64-0.712 1.58-1.62 2.62-1.19 1 0.515 0.855 1.85 0.817 2.8 0.0393 2.77-0.248 5.54-0.855 8.24-0.426 2.12-1.11 4.19-1.48 6.31-0.144 0.601 0.324 1.27-0.175 1.81-0.523 1.1-1.99 0.217-1.66-0.833 0.0822-2.68 1.14-5.2 1.56-7.82 0.169-0.811 0.348-1.89 0.529-2.36 0.343-2.02 0.497-4.08 0.326-6.12-2.18 2.57-3.5 5.7-5.18 8.59-0.454 0.977-1.45 1.56-2.53 1.49-1.5 0.0432-2.51-1.32-3.15-2.51-0.797-1.36-1.18-2.91-2.05-4.23-0.179 1.49-0.575 2.94-0.746 4.43-0.398 2.47-0.879 4.95-0.746 7.47 0.104 1.21-1.63 1.38-1.74 0.164zm1.38-11.7c0.0152-0.0853 0.0674-0.379 0 0z" />
						<path id="u1" d="m140 83.1c0.129-0.519 0.856-0.657 1.17-0.235 0.403 0.447 7e-3 1-0.185 1.45-0.608 1.35-1.21 2.76-1.31 4.26 0.0166 0.441-0.0489 1.31 0.618 1.19 1.05-0.0434 1.73-0.989 2.21-1.82 0.686-1.27 1.17-2.66 2-3.84 0.318-0.408 0.876-0.976 1.41-0.555 0.487 0.356 0.236 1.06 0.175 1.56-0.213 1.12-0.526 2.23-0.584 3.38-0.107 0.712 0.666 0.884 1.13 0.504 1.28-0.695 2.61-1.31 3.85-2.09 0.532-0.426 1.27 0.238 0.979 0.825-0.168 0.466-0.727 0.597-1.08 0.896-1.31 0.813-2.63 1.74-4.17 2.03-0.612 0.127-1.32-0.072-1.63-0.657-0.396-0.633-0.469-1.41-0.437-2.14-0.71 1.2-1.52 2.46-2.78 3.14-0.826 0.436-2.04 0.439-2.62-0.399-0.493-0.659-0.627-1.53-0.419-2.32 0.133-0.742 0.228-1.49 0.505-2.2 0.336-1.01 0.704-2.01 1.16-2.97z" />
						<path id="s1" d="m155 81.9c0.442 0.23 0.571 0.822 0.178 1.17-0.407 0.434-0.984-0.0275-1.47 0.104-0.734 0.108-1.39 0.492-2.05 0.806-0.288 0.179-0.801 0.389-0.922 0.603 0.874 0.513 1.92 0.58 2.81 1.05 0.917 0.386 1.77 1.07 2.08 2.05 0.0188 0.468-0.3 0.8-0.681 1.01-1.47 0.985-3.19 1.54-4.92 1.83-0.869 0.133-1.76 0.161-2.63 0.0451-0.41-0.104-0.676-0.574-0.461-0.962 0.185-0.414 0.686-0.491 1.09-0.412 1.82 0.0731 3.63-0.408 5.29-1.13 0.206-0.148 1.03-0.413 0.491-0.594-1.16-0.626-2.54-0.733-3.63-1.51-0.584-0.342-1.02-0.992-1.03-1.68 7e-3 -0.472 0.46-0.702 0.799-0.932 1.23-0.809 2.61-1.56 4.13-1.58 0.317 8.36e-4 0.634 0.0419 0.938 0.13z" />
						<path id="i1" d="m158 81c0.127-0.426 0.63-0.655 1.02-0.416 0.411 0.191 0.401 0.71 0.284 1.08-0.349 1.68-0.559 3.39-0.796 5.09-0.132 0.799-0.25 1.6-0.445 2.39-0.122 0.466-0.704 0.642-1.08 0.369-0.449-0.344-0.166-0.963-0.116-1.42 0.272-1.47 0.583-2.93 0.706-4.41 0.075-0.903 0.223-1.8 0.432-2.68zm1.5-5.21c0.473 0.0724 0.818 0.612 0.59 1.06-0.281 0.528-1.26 0.516-1.4-0.117-0.168-0.45 0.207-0.91 0.666-0.929 0.0474-0.0033 0.0948-0.0065 0.142-0.0099z" />
						<path id="c2" d="m166 82.1c-0.309 0.31-0.727 5.1e-4 -1.03 0.15-1.33 0.707-2.45 1.86-3 3.27-0.206 0.444-0.201 0.987 0.358 1.13 0.913 0.381 1.94 0.228 2.88 0.066 1-0.199 1.98-0.502 2.95-0.812 0.375-0.129 0.86-0.223 1.15 0.126 0.3 0.303 0.191 0.829-0.178 1.02-0.634 0.261-1.31 0.435-1.96 0.648-1.5 0.46-3.09 0.917-4.67 0.578-0.821-0.179-1.73-0.573-2.03-1.43-0.3-0.944 0.105-1.93 0.563-2.75 0.705-1.18 1.72-2.18 2.87-2.93 0.528-0.325 1.12-0.58 1.74-0.625 0.51 0.0632 0.821 0.649 0.601 1.11-0.0631 0.156-0.151 0.3-0.244 0.439z" />
						<path id="x1" d="m173 81.5c-0.0306 0.597 0.195 1.26-0.1 1.81-0.482 0.601-1.41 0.0954-1.28-0.633-0.0661-1.84-0.016-3.68 0.0779-5.51 0.0812-1.36 0.0413-2.72 0.026-4.08 0.0357-0.776 1.3-0.804 1.38-0.0284 0.0963 0.637 8e-3 1.29 0.0453 1.93-6e-3 1.85-0.0681 3.7-0.145 5.55-0.0467-0.74-0.0136 0.405-0.014 0.631 2e-3 0.111 5e-3 0.223 9e-3 0.334zm0.651 4.24c0.638 0.505-0.0856 1.29-0.653 1.37-0.298 0.543-1.16 0.898-1.52 0.223-0.243-0.612 0.263-1.23 0.728-1.58 0.378-0.459 1.04-0.392 1.45-0.0152zm-0.677-5.13 8e-3 0.106z" />
					</g>
				</g>
			</Rainbow>

		</>
  )
}