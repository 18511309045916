import React, { useState, useEffect, lazy, Suspense, useMemo } from 'react'
import OverlayContainer from './overlay/Overlay'
import Loader from './Loader'
import Profile from'./profiles/Profile'
import Legal from'./legal/Legal'

const Admin = lazy(() => import('./admin/Admin'))
const Forms = lazy(() => import('./admin/Forms'))
const Shop = lazy(() => import('./shop/Shop'))
const ShopZoom = lazy(() => import('./shop/Zoom'))
const Gallery = lazy(() => import('./gallery/Gallery'))
const GalleryZoom = lazy(() => import('./gallery/Zoom'))

export default ({
        openOverlayA, setOpenOverlayA,
        openOverlayB, setOpenOverlayB,
        checkoutItems, setCheckoutItems,
        currentProductIndx, setCurrentProductIndx,
        dynamicCollection, setDynamicCollection,
        loadDynamicAreas,
        sponsorsColl, setSponsorsColl,
        setProgramColl,
        programHostList,
        phoneBtnVis,
        setLoadMainAreas,
        mongo,
        Switch,
        Route,
        useHistory,
        Link,
        setPlayerUrl,
        setPlayerPause,
        playerPause,
        audioStream
}) => {
    
    const [loader, setLoader] = useState(false)
    const [adminSubmitType, setAdminSubmitType] = useState('')
    const [adminUpdateItem, setAdminUpdateItem] = useState({})
    const [updateAdmin, setUpdateAdmin] = useState(false)
    const [updateProgAdmin, setUpdateProgAdmin] = useState(false)
    const [adminArea, setAdminArea] = useState(false)
    const [galleryIndx, setGalleryIndx] = useState(0)
    const [disableOverlayClose, setDisableOverlayClose] = useState(false)
    const [overlayBgs, setOverlayBgs] = useState('')
    const [profileNextPrev, setProfileNextPrev] = useState({next:null, prev:null, lr:false})    
    
    useEffect(() => {
        if(loadDynamicAreas.length === 1) return
        setDynamicCollection([])
        setLoader(true)
        mongo.dbLogin
            .then(() => mongo.db
                .collection(loadDynamicAreas[1])
                .find({},
                    { limit: 50 }
                )
                .toArray()
            )
            .then(docs => {

                loadDynamicAreas[1] === 'sponsors' 
                ?   setDynamicCollection(docs.sort((a,b) => (a.order - b.order)))                
                :   loadDynamicAreas[1] === 'gallery'
                    ?   setDynamicCollection(docs.sort(prev => (prev.youTube !== '' ? 1 : -1)))
                    :   setDynamicCollection(docs)
                
                setLoader(false)
            })
            .catch(err => alert(err))
    }, [
        mongo, 
        setDisableOverlayClose, 
        setDynamicCollection, 
        loadDynamicAreas
    ])

    const memoGallery = useMemo(() => (
        dynamicCollection.length > 0 && 
            <Gallery
            setOpenB={setOpenOverlayB}
            setGalleryIndx={setGalleryIndx}
            dynamicCollection={dynamicCollection}
            loader={loader}
            />
    ), [dynamicCollection, loader, setOpenOverlayB])

    return (
        <Switch>
            <Route path={'/admin'}>
                <OverlayContainer
                open={openOverlayA}
                setOpen={setOpenOverlayA}
                bgColor={'linear-gradient(0deg, rgba(34,26,36,.9) 0%, rgba(13,3,15,1) 75%)'}
                iconColor={'#fff'}
                mediaPhone={phoneBtnVis}
                useHistory={useHistory}
                >
                    <Suspense fallback={<Loader />}>
                        <Admin
                        mongo={mongo}
                        setOpenB={setOpenOverlayB}
                        setAdminArea={setAdminArea}
                        updateAdmin={updateAdmin}
                        setUpdateAdmin={setUpdateAdmin}
                        updateProgAdmin={updateProgAdmin}
                        setUpdateProgAdmin={setUpdateProgAdmin}
                        setAdminUpdateItem={setAdminUpdateItem}
                        setAdminSubmitType={setAdminSubmitType}
                        setProgramColl={setProgramColl}
                        setDisableOverlayClose={setDisableOverlayClose}
                        setLoadMainAreas={setLoadMainAreas}
                        />
                    </Suspense>
                </OverlayContainer>
            
                {
                    openOverlayB &&
                    <OverlayContainer
                    open={openOverlayB}
                    setOpen={setOpenOverlayB}
                    bgColor={'linear-gradient(0deg, rgba(34,26,36,.9) 0%, rgba(13,3,15,1) 75%)'}
                    iconColor={'#fff'}
                    mediaPhone={phoneBtnVis}
                    zindex={15}
                    disableClose={disableOverlayClose}
                    isZoom={true}
                    useHistory={useHistory}
                    >
                        <Suspense fallback={<Loader />}>
                            <Forms
                            mongo={mongo}
                            area={adminArea}
                            setUpdateAdmin={setUpdateAdmin}
                            adminUpdateItem={adminUpdateItem}
                            adminSubmitType={adminSubmitType}
                            setSponsorsColl={setSponsorsColl}
                            sponsorsColl={sponsorsColl}
                            setDisableOverlayClose={setDisableOverlayClose}
                            setLoadMainAreas={setLoadMainAreas}
                            setUpdateProgAdmin={setUpdateProgAdmin}
                            setAdminUpdateItem={setAdminUpdateItem}
                            setOpenOverlayB={setOpenOverlayB}
                            />
                        </Suspense>
                    </OverlayContainer>
                }
            </Route>
            <Route path={'/profile'}>
                <OverlayContainer
                open={openOverlayA}
                setOpen={setOpenOverlayA}
                iconColor={'#fff'}
                mediaPhone={phoneBtnVis}
                bgColor={'linear-gradient(0deg, rgba(34,26,36,.9) 0%, rgba(13,3,15,1) 75%)'}
                useHistory={useHistory}
                bgImage={overlayBgs}
                showNextPrevBtns={true}
                nextPrev={profileNextPrev}
                setNextPrev={setProfileNextPrev}
                Link={Link}
                >
                    <Switch>
                        {programHostList.length > 0 && 
                            programHostList
                            .map((host, i, arr) => {

                                let mashTitle = (title) => (
                                    arr[title].title.indexOf(' ') !== -1 
                                        ? arr[title].title.split(' ').map(i => i.charAt(0)).join('').replace(/\W/g, '').toLowerCase() 
                                        : arr[title].title.replace(/\W/g, '').toLowerCase()
                                )

                                return <Route key={i} path={`/profile/${mashTitle(i)}`}>
                                            <Profile
                                            nextPath={mashTitle(i < arr.length-1 ? i+1 : 0)}
                                            prevPath={mashTitle(i > 0 ? i-1 : arr.length-1)}
                                            currPath={mashTitle(i)}
                                            mongo={mongo}
                                            host={host}
                                            setOverlayBgs={setOverlayBgs}
                                            nextPrev={profileNextPrev}
                                            setNextPrev={setProfileNextPrev}
                                            setPlayerUrl={setPlayerUrl}
                                            setPlayerPause={setPlayerPause}
                                            playerPause={playerPause}
                                            audioStream={audioStream}
                                            />
                                        </Route>                                
                                }
                            )
                        }
                    </Switch>
                </OverlayContainer>
            </Route>    
            <Route path={'/docs'}>
                <OverlayContainer
                open={openOverlayA}
                setOpen={setOpenOverlayA}
                iconColor={'#fff'}
                mediaPhone={phoneBtnVis}
                bgColor= 'linear-gradient(0deg, rgba(34,26,36,.7) 0%, rgba(13,3,15,1) 85%)'
                bgImage={'/assets/legal/bookShelf.jpg'}
                bgRepeat={true}
                useHistory={useHistory}
                >
                    <Legal dynamicCollection={dynamicCollection} />
                </OverlayContainer>
            </Route>
            <Route path={'/shop'}>
                <OverlayContainer
                open={openOverlayA}
                setOpen={setOpenOverlayA}
                mediaPhone={phoneBtnVis}
                topNav={true}
                bgColor={'linear-gradient(0deg, rgba(255,255,255,0.8) 0%, rgba(247, 240, 217,1) 75%)'}
                iconColor={'#2A2A2A'}
                useHistory={useHistory}
                >
                    <Suspense fallback={<Loader />}>
                        <Shop
                        setOpenB={setOpenOverlayB}
                        checkoutItems={checkoutItems}
                        setCheckoutItems={setCheckoutItems}
                        setCurrentProductIndx={setCurrentProductIndx}
                        dynamicCollection={dynamicCollection}
                        loader={loader}
                        />
                    </Suspense>
                </OverlayContainer>
            
                {
                    openOverlayB &&
                    <OverlayContainer
                    zindex={15}
                    open={openOverlayB}
                    setOpen={setOpenOverlayB}
                    mediaPhone={phoneBtnVis}
                    topNav={false}
                    bgColor={'linear-gradient(0deg, rgba(42,42,42,0.7) 0%, rgba(42,42,42,1) 75%)'}
                    iconColor={'#fff'}
                    disableClose={disableOverlayClose}
                    isZoom={true}
                    useHistory={useHistory}
                    >
                        <Suspense fallback={<Loader />}>
                            <ShopZoom
                            currentProductIndx={currentProductIndx}
                            dynamicCollection={dynamicCollection}
                            />
                        </Suspense>
                    </OverlayContainer>
                }
            </Route>
            <Route path={'/gallery'}>
                <OverlayContainer
                open={openOverlayA}
                setOpen={setOpenOverlayA}
                mediaPhone={phoneBtnVis}
                topNav={false}
                bgColor={'linear-gradient(0deg, rgba(42,42,42,0.8) 0%, rgba(13,3,15,1)75%)'}
                iconColor={'#fff'}
                useHistory={useHistory}
                >
                    <Suspense fallback={<Loader />}>
                        {memoGallery}
                    </Suspense>
                </OverlayContainer>
            
                {
                    openOverlayB &&
                    <OverlayContainer
                    zindex={15}
                    open={openOverlayB}
                    setOpen={setOpenOverlayB}
                    mediaPhone={phoneBtnVis}
                    topNav={false}
                    bgColor={'linear-gradient(0deg, rgba(13,3,15,0.6) 0%, rgba(13,3,15,1) 75%)'}
                    iconColor={'#fff'}
                    disableClose={disableOverlayClose}
                    isZoom={true}
                    useHistory={useHistory}
                    >
                        <Suspense fallback={<Loader />}>
                            <GalleryZoom
                            galleryIndx={galleryIndx}
                            dynamicCollection={dynamicCollection}
                            />
                        </Suspense>
                    </OverlayContainer>
                }
            </Route>
        </Switch>
    )
}



