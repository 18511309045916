import React, {useMemo} from 'react'
import { Center, RainbowCont, RadioCont, RadioTxt, Broadcast } from './headerStyles'
import { FaBroadcastTower } from 'react-icons/fa'
import RainbowSvg from './RainbowSvg'
import Rocks from './Rocks'
import PlayBtn from './buttons/PlayBtn'

const url = 'https://uk5.internet-radio.com/proxy/nimfm?mp=/stream'
const http_url = 'http://uk5.internet-radio.com:8055/stream?type=http&nocache=327'

export default ({
    currentShow, 
    setPlayerUrl, 
    playerUrl, 
    setPlayerPause, 
    playerPause, 
    audioStream
}) => {

    const MemoRocks = useMemo(() => (<Rocks />), [])
    const MemoRainbow = useMemo(() => (
        <RainbowSvg pause={playerUrl.url === url ? playerPause.pauseplay : false} />
    ), [playerPause, playerUrl])

    return(
        <>
            {MemoRocks}
            <Center>
                <RainbowCont>
                    {MemoRainbow}
                </RainbowCont>
                <PlayBtn
                setPlayerUrl={setPlayerUrl}
                urls={{url, http_url}}
                setPlayerPause={setPlayerPause}
                playerPause={playerPause}
                audioStream={audioStream}
                />
            </Center>
            <RadioCont>                                     
                <Broadcast>
                    Broadcasting from Nimbin, NSW, Australia
                    <RadioTxt>102.3</RadioTxt> 
                    <FaBroadcastTower size={40} color={'#fff8c2'} style={{transform:`translateY(5px)`}} />
                </Broadcast>
            </RadioCont>
        </>
    )
}



