import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    useLocation

} from "react-router-dom"
import Main from './components/Main'
import media from './components/media'
import { createGlobalStyle }  from "styled-components"

const GlobalStyles = createGlobalStyle`
    html, body{
        background: #2A2A2A;
        color:papayawhip;
        height:100%;
        font-family: 'Merriweather', serif;
    }
    a {
        color: #C7A4C4;
        text-decoration: none;
    }
    .limelight{
        font-family: 'Limelight', cursive;
        font-size: 4.5rem;
    }
    .headline{
        display:flex;
        align-items:flex-start;
        justify-content:center;
        line-height:1.4;
        margin-bottom:20px;
        text-align:center;
        ${media.desktop`font-size: 3.3rem;`}
        ${media.laptop`font-size: 3rem;`}
        ${media.tablet`font-size: 2.5rem;`}
        ${media.phone`font-size: 2rem;`}
    }
    .body-copy{
        font-size:1.1rem;
        line-height:1.6;
        ${media.tablet`font-size: 1rem;`}
        ${media.phone`font-size: 0.9rem;`}
    }
  `

export default props => {
    return(
        <>
		    <GlobalStyles />
            <Router>
                <Main {...{
                    Switch,
                    Route,
                    Link,
                    useHistory,
                    useLocation
                }} />
            </Router>
		</>
    )
}

