import React from 'react'
import {Wrapper, Title, SubTitle, Item, ItemTitle, ContUl, ScrollBox} from './podcastStyles'
import PlayBtn from '../buttons/PlayBtn'

export default ({
    titles, 
    urls,        
    setPlayerUrl,
    setPlayerPause,
    playerPause,
    audioStream,
    currPath
}) => {

    
    return  <Wrapper>
        <Title>Podcasts</Title>
        <ScrollBox>
            {titles.map((title, i) => (
                <Item key={`${i}podplay`}>
                    <ContUl>
                        <PlayBtn
                        setPlayerUrl={setPlayerUrl}
                        setPlayerPause={setPlayerPause}
                        playerPause={playerPause}
                        indx={i} 
                        urls={{url:urls[i], http_url:urls[i], title, currPath}}
                        small={true}
                        audioStream={audioStream}
                        />
                        <ItemTitle>{title}</ItemTitle>
                    </ContUl>
                </Item>
            ))}
        </ScrollBox>
        <SubTitle>Live stream not doing it for you? Check out some epic shows from the past.</SubTitle>
    </Wrapper>
}