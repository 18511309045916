import styled from 'styled-components'
import media from './media'

export const BodyTwo = styled.div`
    max-width:1000px;
    min-width:260px;
    color:rgb(33,24,28);
    white-space:pre-line;
    line-height:1.7;
    margin:70px;
    font-size:1.3rem;

    ${media.phone`text-align:center; margin:30px; font-size:1rem;`}
` 
export const SnakeCont = styled.div`
    display: flex;
    justify-content: center;
    width:100%;
    height:100%;
    overflow:hidden;  
  
` 
export const Snake = styled.img`

    ${media.phone`width:280%;`}   
` 