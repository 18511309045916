import styled, { createGlobalStyle } from "styled-components"
import {animated} from "react-spring"
import media from '../media'

export const BodyOverflow = createGlobalStyle`body{overflow:${props => props.bodyOverflow ? 'hidden' : 'auto'};}`

export const Overlay = styled(animated.div)`
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    display:flex;
    align-items:center;
    justify-content:center;
    z-index:${props => props.zindex || 10} ;
`
export const Overflow = styled.div`
    position:absolute;
    top:${props => props.topNav ? `50px;` : `0;`};
    bottom:0;
    left:6%;
    right:6%;
    overflow-x: hidden;
    max-width:1200px;
    margin-left: auto; 
    margin-right: auto; 
    background:${props => props.bgColor};
    box-shadow: 0 0 20px 8px rgba(13,3,15,0.3);
    z-index:100;

    ${media.phone`
        left:0;
        right:0;
        `
    }
`
export const Close = styled.div`
    position:fixed;
    top:0;
    bottom:0;
    left:0;
    right:0; 
    cursor:pointer;
    z-index:${props => props.zindex || 10} ;
`
export const PhoneCloseMobile = styled.div`
    position:absolute;
    top:${props => props.topNav ? '12vh' : '15px'};
    right:15px;
    cursor:pointer;
    z-index:200;
`
export const BgImage = styled.div`
    position:absolute;
    top:0;
    bottom:0;
    left:6%;
    right:6%;
    max-width:1200px;
    margin-left: auto; 
    margin-right: auto; 
    background: url(${props => props.url}) ${props => props.bgRepeat ? 'repeat' : 'no-repeat  center'};
    background-size: ${props => props.bgRepeat ? 'auto' : 'cover'};

    ${media.phone`
        left:0;
        right:0;
        `
    }
`
export const PrevNextNav = styled.div`
    position:absolute;
    top:45vh;
    ${props => props.l ? 'left:10px' : 'right:10px'};
`