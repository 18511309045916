import styled from 'styled-components'
import media from './media'

export const BodyThree = styled.div`
    max-width:1000px;
    min-width:260px;
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    justify-content:space-evenly;
    align-content:flex-start;
    margin:75px 30px;
    color:rgb(33,24,28);
` 
export const SponsorCont = styled.div`
    display:flex;
    flex-flow:column;
    align-items:center;
    justify-content:center;
    width:130px;
    height:200px;
    padding:10px;
    margin:10px;
    border-radius:12px;
    font-size:1rem;
    background:#FFF;
    box-shadow: 2px 2px 2px rgba(33,24,28,0.3);
    cursor:pointer;

    &:hover{
       box-shadow:3px 3px 3px rgba(33,24,28,0.3);
    }
    
    ${media.phone`
        width:80px;
        height:150px;
        font-size:.8rem; 
        padding:5px; 
        margin:2px; 
        box-shadow:1px 1px 1px rgba(33,24,28,0.3);
        `
    }
` 
export const SponsorBg = styled.div`
    width:110px;
    height:110px;
    border-radius:50%;
    background: url(${props => props.url});
    background-position:center center;
    background-size:cover;
    margin-bottom:10px;
    ${media.phone`width:60px; height:60px; `}
` 
export const TextCont = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
    height:100%;
    ${ props => props.marg ? 'margin:20px 0 20px 0' : 'margin-bottom:20px'};
    text-align:center;
` 
export const IntroText = styled.div`
    max-width:700px;
` 