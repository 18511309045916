import React, { useState, useEffect, useLayoutEffect, useCallback } from 'react'
import { BodyOne, Days, Day, Listing, Time, TimeNum, AmPm, Title } from './bodyOneStyles'
import {HeadlineTrail} from './springAnimations'
import {animated, useTrail, config} from 'react-spring'

let hline = ['Get', 'with', 'the', 'Program']
let timeout
let onlineEv = false
let updateNoSelect = false

export default ({        
    scrollFlag,
    programColl, 
    unixTime:{
        getDay,
        getHour,
        getMins,
        getSecs
    }, 
    getUnixTime,
    setUnixTime, 
    setCurrentShow, 
    Link}) => {

    const [selected, setSelected] = useState(getDay)
    const [today, setToday] = useState(programColl[getDay].hosts)

    let times = programColl[getDay].hosts.map(v => (Number(v.time)))

    let justDays = programColl.map(day => day.day)

    let foundHour = getHour >= times[0]
    ?   times.reduce((prev, curr) => Math.abs(curr - getHour) < Math.abs(prev - getHour) && curr <= getHour 
        ? curr 
        : prev)
    :   0

    console.log(foundHour)

    let showIndx = foundHour ? times.indexOf(foundHour) : 'before-hours'
    
    let selectedTime = useCallback(() => {
        
        let nextShowHour = showIndx !== 'before-hours' && showIndx+1 < times.length 
        ?   times[showIndx+1] 
        :   0,

        hoursToMilSecs =  nextShowHour > getHour || nextShowHour === 0
        ? getHour <= times[0] 
            ? 3600000 * (times[0] - getHour) // before first show, after midnight
            : 3600000 * (nextShowHour - getHour) // after fist show

        : 3600000 * (24 - getHour), // after last show, before midnight

        minsToMilSecs = 60000 * getMins,
        secsToMilSecs = 1000 * getSecs,

        delay = Math.abs(hoursToMilSecs - minsToMilSecs - secsToMilSecs)

        window.clearTimeout(timeout)
        timeout = window.setTimeout(() => { 
            updateNoSelect = false               
            getUnixTime(wtResolved => {
                setUnixTime(wtResolved)
            }) 
        }, delay)

        updateNoSelect = true               

    }, [getHour, getMins, getSecs, getUnixTime, setUnixTime, showIndx, times])

    let selectDelay = (i, others) => (
        !others
        ?   showIndx === i  && (getDay === selected && getHour >= times[0] )
        :   showIndx === i && getDay === selected)

    let mashTitle = (title) => (
        title.indexOf(' ') !== -1 
            ? title.split(' ').map(i => i.charAt(0)).join('').replace(/\W/g, '').toLowerCase() 
            : title.replace(/\W/g, '').toLowerCase()
    )

    const [trail, setTrail] = useTrail(today.length, () => ({to:{opacity: 1}, from:{opacity: 0}}))
    const [intro, setIntro] = useTrail(hline.length, () => ({opacity: 0, x: 20, height: 0}))
    
    const changeDay = useCallback(i => e => {
        e && e.preventDefault()
        setSelected(i)
        setToday(programColl[i].hosts)
        setTrail({to:{opacity: 1,top:0}, from:{opacity: 0,top:'-75px'}, reset:true})
    }, [setTrail, programColl])

    const offLineWake = useCallback(() => {
        if(onlineEv) return
        onlineEv = true
        window.addEventListener('online', () => { 
            updateNoSelect = false
            getUnixTime(wtResolved => {
                setUnixTime(wtResolved)
                //setUnixTime(state => ({...state, getHour:23, getDay:1}))
            })                
        })
        //window.addEventListener('offline',  () => {one = one ? false : true;  console.log('offline')})
    }, [getUnixTime, setUnixTime])

    useLayoutEffect(() => {
        if(updateNoSelect) return
        setSelected(getDay)
        setToday(programColl[getDay].hosts)
    },[getDay, programColl])

    let currentShowEffect = useCallback(() => {
        getHour >= times[0]
        ?   setCurrentShow({title:programColl[getDay].hosts[showIndx].title, started:true}) 
        :   setCurrentShow({title:`First show starts at: ${times[0]} AM`, started:false})
    },[setCurrentShow, showIndx, programColl, getHour, getDay, times])

    useEffect(() => {
        !updateNoSelect && currentShowEffect()
    }, [currentShowEffect])

    useEffect(() => {offLineWake()}, [offLineWake])

    useEffect(() => {
        !updateNoSelect && selectedTime()
    },[getDay, getHour, selectedTime])

    useEffect(() => {
        scrollFlag.once && setIntro({opacity: 1, x: 0, height: 80, config:config.default})
    }, [scrollFlag, setIntro])

    return (
        <BodyOne className={'body-copy'}>
                <HeadlineTrail intro={intro} hline={hline} />
            <Days>
                {
                    justDays.map((day, i) => <Day key={`days${i}`} selected={i === selected} onClick={changeDay(i)} >{day.toUpperCase()}</Day>)
                }
            </Days>
            <ul>
                {
                    trail.map((prop, i) =>
                        <Link key={'today'+i}  to={`/profile/${mashTitle(today[i].title)}`}>
                            <animated.div style={prop}>
                                    <Listing 
                                    bg={i % 2 ? true : false} 
                                    >
                                        <Time getHour={selectDelay(i)}>
                                            <TimeNum selected={selectDelay(i,true)}>
                                                {Number(today[i].time) <= 12 ? today[i].time : today[i].time-12}
                                            </TimeNum>
                                            <AmPm selected={selectDelay(i,true)}>
                                                {Number(today[i].time) < 12 ? 'AM' : 'PM'}
                                            </AmPm>
                                        </Time>
                                        <Title>{today[i].title}</Title>
                                    </Listing>
                            </animated.div>
                        </Link>
                    )
                }
            </ul>
        </BodyOne >
    )
}