import React, {useEffect} from 'react'
import {useTrail, config} from 'react-spring'
import {HeadlineTrail} from './springAnimations'
import { BodyThree, SponsorBg, SponsorCont, TextCont, IntroText } from './bodyThreeStyles'
import { FaHeart} from "react-icons/fa"

const Intro = props => (
    <IntroText>
        These businesses are our Sponsors. We {<FaHeart size={20} color={'red'}/>} them! Check them out, support them. We’re sure you’ll {<FaHeart size={20} color={'red'}/>} them too.
    </IntroText>)

const Img = (item, i) => (
    <SponsorCont key={`img${i}`} onClick={() => window.open(item.site, '_blank')}>
        <SponsorBg  url={item.thumbnail} />
        <span style={{textAlign:'center', lineHeight:'1.2'}}>{item.title}</span>
    </SponsorCont>
    )

let hline = [`Sponsors`, '&', 'Supporters']

export default ({sponsorsColl, scrollFlag}) => {

    const [intro, setIntro] = useTrail(hline.length, () => ({opacity: 0, x: 20, height: 0}))

    useEffect(() => {
        scrollFlag.once && setIntro({opacity: 1, x: 0, height: 80, config:config.default})
    }, [scrollFlag, setIntro])

    return (
        <BodyThree className={'body-copy'}>
            <HeadlineTrail intro={intro} hline={hline} />
                {
                    sponsorsColl.map((item, i) => i === sponsorsColl.length-2 //grants text
                        ?   <React.Fragment key={`grant${i}`}>
                                <TextCont marg={true}>
                                    <IntroText>
                                        And these organisations are our supporters, either through grants made available by the CBF, material made available through AMRAP and the financial and industry benefits from the CBAA. We {<FaHeart size={20} color={'red'}/>} them just as much.
                                    </IntroText>
                                </TextCont> 
                                    {Img(item, i)}
                                </React.Fragment>
                        : i === 0 //intro text
                            ?   <React.Fragment key={`intro{i}`}>
                                    <TextCont marg={false}>
                                        <Intro />
                                    </TextCont> 
                                    {Img(item, i)}
                                </React.Fragment>
                            :   Img(item, i)
                    )
                }
        </BodyThree>
    )
}
