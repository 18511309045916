import React from 'react'
import { Main, Doc, DocName, ActionLabel } from './legalStyles'

export default ({dynamicCollection}) => {

    return (
        <Main>
            <p className={'headline'}>Behind the filing cabinet</p>
            {dynamicCollection.map((item,i) => (
                <Doc 
                key={`${i}lnk`}
                href={item.url} 
                target="_blank" 
                rel="noopener noreferrer"
                >
                    <DocName>
                        {item.description}                    
                    </DocName>
                    <ActionLabel>Opens new tab</ActionLabel>
                </Doc>))}
        </Main>
    )
}



