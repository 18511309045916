import styled from "styled-components"
import media from './media'

export const MainHeader = styled.div`
    position:relative;
    display:flex;
    align-items:flex-start;
    justify-content:center;
    height:100vh;
    overflow:hidden;
`
export const MainBodyOne = styled.div`
    position:relative;
    display:flex;
    align-items:center;
    justify-content:center;
    min-height:100vh;
    background: linear-gradient(9deg, rgba(13,13,13,0) 45%, rgba(13,13,13,1) 75%) center center, 
    linear-gradient(3deg, rgba(244,237,230,1) 10%, rgba(255,248,194,0) 40%) center center,
    url('/assets/one/fuck.png') center center;
    background-size: cover;
    background-repeat:no-repeat;
`
export const MainBodyTwo = styled.div`
    position:relative;
    display:flex;
    flex-flow:column;
    align-items:center;
    justify-content:center;
    background:linear-gradient(0deg, rgba(255,248,194,1) 0%, rgba(244,237,230,1) 100%);
    min-height:100vh;
    background-position:center center;
    background-size:cover;
`
export const MainBodyThree = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    min-height:100vh;
    background:linear-gradient(0deg, white 90%, #FFF8C2 100%);
    color:#2A2A2A;
`
export const MainBodyFour = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    min-height:100vh;
    background:#21181C;
`
export const PhoneNavBtn = styled.div`
    position:fixed;
    z-index:3;
    top:10px;
    left:10px;
    cursor:pointer;
    visibility:hidden;

    ${media.phone`visibility:visible;`}
`
export const Circle = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    width:50px;
    height:50px;
    background:rgba(42,42,42,0.5);
    border-radius:50%;
`
export const BigClose = styled.div`
    position:fixed;
    top:0;
    left:0;    
    right:0;
    bottom:0;
    visibility:hidden;

${media.phone`visibility:visible;`}
`