import styled from 'styled-components'
import media from './media'

export const BodyOne = styled.div`
    max-width:1000px;
    min-width:260px;

    ${media.desktop`margin: 0px;`}
    ${media.laptop`margin: 80px 30px 30px;`}
    ${media.phone`margin: 10px 30px; min-width:100%;`}
` 
export const Days = styled.ul`
    display:inline-flex;
    flex-wrap:wrap;
    align-items:center;
    justify-content:center;
    margin-top:25px;
`
export const Day = styled.li`
    margin: 0 3px 6px 3px;
    color:${props => props.selected ? 'rgba(33,24,28,1)' : '#C7A4C4'};
    font-family:Arial, Helvetica, sans-serif;
    font-size:large;
    cursor:pointer;
    background:${props => props.selected ? '#FFF8C2' : 'rgba(33,24,28,1)'};
    padding: 5px 25px;
    border-radius: 3px;
    box-shadow: 1px 1px 2px rgba(13,3,15,1);

    &:hover{
        color:#FFF8C2;
        background:rgba(33,24,28,1) ;
    }

    ${media.phone`font-size:medium; padding: 5px 10px;`}
`
export const Listing = styled.li`
    position:relative;
    display:flex;
    align-items:center;
    padding:25px 15px 25px 20%;
    background:${props => props.bg ? 'rgba(33,24,28,0.9) ' : 'rgba(33,24,28,0.8) '};
    margin-top: 3px;
    cursor:pointer;
    box-shadow: 2px 2px 3px rgba(13,3,15,1);
    border-radius: 3px;
    color:papayawhip;
    font-size:1.3rem;

    &:hover{
        color:white; 
        background:rgba(41,50,51,0.9);
    }
    
    ${media.phone`padding:15px 15px 15px 25%;`}
`
export const GotProfile = styled.div`
    width:10px;
    height:10px;
    background:lime;
    border-radius:50%;
`
export const Time = styled.div`
    position:absolute;
    top:0;
    left:0;
    right:85%;
    bottom:0;
    display:flex;
    flex-flow:wrap;
    align-items:center;
    justify-content:center;
    color:#FFF8C2;
    background:${props => props.getHour ? 'red' : 'rgba(13,3,15,0.7)'};
    padding:0 10px;

    &:before{
    content: "";
    position: absolute;
    right: -20px;
    top:0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 20px solid ${props => props.getHour ? 'red' : 'rgba(13,3,15,0.7)'};
    border-top: 43px solid transparent;
    border-bottom: 43px solid transparent;
    }

    ${media.phone`
        right:80%;

        &:before{
            right: -15px;
            border-left: 15px solid ${props => props.getHour ? 'red' : 'rgba(13,3,15,0.7)'};
            border-top: 28px solid transparent;
            border-bottom: 28px solid transparent;
        }
    `}
`
export const TimeNum = styled.span`
    font-size:1.8rem;
    ${props => props.selected && 'color:rgba(13,3,15,1);'}

    ${media.phone`font-size:1.3rem;`}
`
export const AmPm = styled.span`
    position: absolute;
    top:5px;
    left: 10px;
    font-size:0.8rem;
    color:${props => props.selected ? 'rgba(13,3,15,1)' : '#C7A4C4'};

    ${media.phone`top:2px; left:4px; font-size:0.6rem;`}
`
export const Title = styled.span`
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;

    ${media.phone`font-size:1rem;`}

`