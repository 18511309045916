import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react'
import mongo from '../db'
import { MainHeader, MainBodyOne, MainBodyTwo, MainBodyThree, MainBodyFour, PhoneNavBtn, Circle, BigClose} from './mainStyles'
import { animated, useSpring } from 'react-spring'
import Header from './Header'
import BodyOne from './BodyOne'
import BodyFour from './BodyFour'
import BodyTwo from './BodyTwo'
import BodyThree from './BodyThree'
import Navbar from './Navbar'
import MainOverlays from './MainOverlays'
import { FiMenu } from "react-icons/fi"
import {worldTime} from './worldTime'
import browserDetect from '../browserDetect'
import PlayerCont from './PlayerCont'

export default ({
    Switch,
    Route,
    Link,
    useHistory,
    useLocation
}) => {
    
    const [openOverlayA, setOpenOverlayA] = useState(false)
    const [openOverlayB, setOpenOverlayB] = useState(false)
    const [navOpen, setNavOpen] = useState(true)
    const [checkoutItems, setCheckoutItems] = useState([])
    const [currentProductIndx, setCurrentProductIndx] = useState(0)
    const [phoneBtnVis, setPhoneBtnVis] = useState(false)
    const [scrollFlag, setScrollFlag] = useState({})
    //const [flagHead, setFlagHead] = useState({})
    const [flagOne, setFlagOne] = useState({})
    const [flagTwo, setFlagTwo] = useState({})
    const [flagThree, setFlagThree] = useState({})
    const [flagFour, setFlagFour] = useState({})
    const [programColl, setProgramColl] = useState([])
    const [programHostList, setProgramHostList] = useState([])
    const [sponsorsColl, setSponsorsColl] = useState([])
    const [generalColl, setGeneralColl] = useState([])
    const [loadDynamicAreas, setLoadDynamicAreas] = useState([false])
    const [dynamicCollection, setDynamicCollection] = useState([])
    const [loadMainAreas, setLoadMainAreas] = useState([false, 'general', 'program', 'sponsors'])
    const [unixTime, setUnixTime] = useState({})
    const [currentShow, setCurrentShow] = useState({})
    const [playerUrl, setPlayerUrl] = useState({})
    const [playerPause, setPlayerPause] = useState({})

    let audioStream = useRef(new Audio())

    let browser = browserDetect()

    const getUnixTime = useCallback( callback => {
        let wtError = callback => setTimeout(() => {getUnixTime(callback)}, 5000)
        new Promise((resolve, reject) => resolve( worldTime() ))
        .then(wt => {
            let date = new Date(wt.unixtime*1000)      
            callback({
                getDay:date.getDay(),
                getHour:date.getHours(),
                getMins:date.getMinutes(),
                getSecs:date.getSeconds()
            })
        })
        .catch(err => wtError(callback))
    }, [])

    useEffect(() => {
        loadMainAreas.forEach((area, i) => {
            typeof area === 'string' && mongo.dbLogin
            .then(() => mongo.db
                .collection(area)
                .find({}, {projection:{ _id: 0 }})
                .toArray()
            )
            .then(docs => {
                if(area === 'general') setGeneralColl(docs)
                else if(area === 'program'){
                    setProgramColl(docs.sort((a,b) => (a.indx - b.indx)))
                    let justTitles = docs
                    .sort((a,b) => (a.indx - b.indx))
                    .map(day => day.hosts.map(host => {host.day = day.day; return host}))
                    .reduce((a, b) => a.concat(b), [])

                    let filterDups = justTitles.filter((v,i,a) => a.findIndex(t => (t.title === v.title)) === i)
                    
                    setProgramHostList(filterDups)
                        
                    getUnixTime(wtResolved => setUnixTime(wtResolved))                    
                }
                else if(area === 'sponsors') setSponsorsColl(docs.sort((a,b) => (a.order - b.order)))
            })
            .catch(err => alert(err))
        })
    }, [loadMainAreas, getUnixTime])

    let browserLocPath = useLocation().pathname 
    let history = useHistory()
    useEffect(() => {
        if(browserLocPath === '/docs'){
            setLoadDynamicAreas(state => [!state[0], 'docs'])
            history.push(browserLocPath)
        }
        if(browserLocPath === '/shop'){
            setLoadDynamicAreas(state => [!state[0], 'shop'])
            history.push(browserLocPath)
        }
        if(browserLocPath === '/gallery'){
            setLoadDynamicAreas(state => [!state[0], 'gallery'])
            history.push(browserLocPath)
        }
    }, [browserLocPath, history])
    
    useEffect(() => {
        !phoneBtnVis && setNavOpen(true)
        phoneBtnVis && setNavOpen(false)
    }, [phoneBtnVis])

    useEffect(() => {
        //if(scrollFlag.indx === 0) setFlagHead(scrollFlag)
        if(!flagOne.once && scrollFlag.indx === 1 && scrollFlag.key === 'top') setFlagOne(state => ({...scrollFlag, once:true}))
        else if(!flagTwo.once && scrollFlag.indx === 2 && scrollFlag.key === 'top') setFlagTwo(state => ({...scrollFlag, once:true}))
        else if(!flagThree.once && scrollFlag.indx === 3 && scrollFlag.key === 'top') setFlagThree(state => ({...scrollFlag, once:true}))
        else if(!flagFour.once && scrollFlag.indx === 4 && scrollFlag.key === 'top') setFlagFour(state => ({...scrollFlag, once:true}))
    }, [scrollFlag, flagOne.once, flagTwo.once, flagThree.once, flagFour.once])

    const scrollRefs = [
        { name: 'LIVE', ref: useRef(null) },
        { name: 'PROGRAM', ref: useRef(null) },
        { name: `ABOUT`, ref: useRef(null) },
        { name: 'SPONSORS', ref: useRef(null) },
        { name: 'CONTACT', ref: useRef(null) }
    ]

    const { y } = useSpring({
        opacity: navOpen ? 1 : 0, y: navOpen ? '0' : '-420px'
    })

    const memoHead= useMemo( () => (
        <Header 
        currentShow={currentShow}
        setPlayerUrl={setPlayerUrl}
        playerUrl={playerUrl}
        setPlayerPause={setPlayerPause}
        playerPause={playerPause}
        audioStream={audioStream.current}
        />
    ), [currentShow, playerPause, playerUrl])

    const memoOne= useMemo( () => (
        <BodyOne
        scrollFlag={flagOne}
        programColl={programColl}
        unixTime={unixTime}
        getUnixTime={getUnixTime}
        setUnixTime={setUnixTime}
        setCurrentShow={setCurrentShow}
        Link={Link}
        />
    ), [flagOne, programColl, getUnixTime, unixTime, setCurrentShow, Link])

    const memoTwo= useMemo( () => (
        <BodyTwo
        scrollFlag={flagTwo}
        generalColl={generalColl}
        />  
    ), [flagTwo, generalColl])

    const memoThree = useMemo( () => (
        <BodyThree 
        scrollFlag={flagThree}
        sponsorsColl={sponsorsColl}
        />
    ), [flagThree, sponsorsColl])

    const memoFour= useMemo( () => (
        <BodyFour
        scrollFlag={flagFour}
        />  
    ), [flagFour])

    const memoPlayer= useMemo( () => (
        <PlayerCont
        playerUrl={playerUrl}
        setPlayerPause={setPlayerPause}
        playerPause={playerPause}
        browser={browser}
        audioStream={audioStream.current}
        currentShow={currentShow}
        mongo={mongo}
        Link={Link}
        inOverlayB={openOverlayB}
        />
    ), [browser, playerPause, playerUrl, currentShow, Link, openOverlayB])

    const memoNavbar = useMemo( () => (
        <div style={{ position: 'fixed', zIndex:'3', top: '0', left: '0', bottom:'100%', width: '100%' }}>
                    <animated.div style={{ transform: y.interpolate(y => `translate3d(0, ${y}px, 0)`) }}>
                        <Navbar
                        scrollRefs={scrollRefs}
                        setPhoneBtnVis={setPhoneBtnVis}
                        setScrollFlag={setScrollFlag}
                        setNavOpen={setNavOpen}
                        setLoadDynamicAreas={setLoadDynamicAreas}
                        Link={Link}
                        />
                    </animated.div>
                </div>
    ), [ scrollRefs, y, Link])

    const memoOverlays = useMemo( () => (
        <MainOverlays
            {
                ...{
                    openOverlayA, setOpenOverlayA,
                    openOverlayB, setOpenOverlayB,
                    checkoutItems, setCheckoutItems,
                    currentProductIndx, setCurrentProductIndx,
                    dynamicCollection, setDynamicCollection,
                    loadDynamicAreas,
                    sponsorsColl, setSponsorsColl,
                    setProgramColl,
                    programHostList,
                    phoneBtnVis,
                    setLoadMainAreas,
                    mongo,
                    Switch,
                    Route,
                    useHistory,
                    Link,
                    setPlayerUrl,
                    setPlayerPause,
                    playerPause,
                    audioStream
                }
            }
        />
    ), [
        openOverlayA, setOpenOverlayA,
        openOverlayB, setOpenOverlayB,
        checkoutItems, setCheckoutItems,
        currentProductIndx, setCurrentProductIndx,
        dynamicCollection, setDynamicCollection,
        loadDynamicAreas,
        sponsorsColl, setSponsorsColl,
        setProgramColl,
        programHostList,
        phoneBtnVis,
        setLoadMainAreas,
        Switch,
        Route,
        useHistory,
        Link,
        playerPause
    ])

    return (
        <>
            {memoPlayer}
            <MainHeader ref={scrollRefs[0].ref}>{memoHead}</MainHeader>
            <MainBodyOne ref={scrollRefs[1].ref}>{unixTime.getHour !== undefined && memoOne}</MainBodyOne>
            <MainBodyTwo ref={scrollRefs[2].ref}>{memoTwo}</MainBodyTwo>
            <MainBodyThree ref={scrollRefs[3].ref}>{memoThree}</MainBodyThree>
            <MainBodyFour ref={scrollRefs[4].ref}>{memoFour}</MainBodyFour>
            <Route exact path='/'>
                {memoNavbar}
                <PhoneNavBtn onClick={e => setNavOpen(!navOpen)}>
                    <Circle><FiMenu size={30} /></Circle>
                </PhoneNavBtn>
                {navOpen && <BigClose onClick={e => setNavOpen(false)} />}
            </Route>
            {memoOverlays}
        </>
    )
}



