import styled from "styled-components"
import media from '../media'

export const Main = styled.div`
    position:relative;
    flex-direction:column;
    display:flex;
    align-items:center;
    justify-content:center;
    padding:30px;

    ${media.phone`padding-top:45px; margin-bottom:50px;`}
`
export const Txt = styled.p`
    max-width:700px;
    margin:25px;
    white-space:pre-line;

    &:first-line {
    font-size:1.5rem;
}
`
export const Circle = styled.div`
    border-radius:50%;
    width:25vw;
    height:25vw;
    background: url(${props => props.url});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
	box-shadow: 5px 5px 10px rgba(13,3,15, 1);

    ${media.phone`
        width:50vw;
        height:50vw;
        `
    }
`
export const ImageCont = styled.div`
    border-radius:30px;
    width:60vw;
    height:50vw;
    background: url(${props => props.url});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin:25px;
	box-shadow: 5px 5px 10px rgba(13,3,15, 1);

    ${media.phone`
        width:50vw;
        height:50vw;
        `
    }
`
export const ShowLink = styled.a`
    width:100%;
    display:flex;
    justify-content:center;
    max-width:700px;
    background:rgba(13,3,15,1);
    padding:15px;
    margin-top:1px;
    cursor: pointer;
    &:hover{
        background:rgba(13,3,15,0.5);
        color:white;
    }
`