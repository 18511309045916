import React, {useState, useEffect, useCallback, useRef} from 'react'
import styled from "styled-components"
import { FaPlay, FaPause, FaMicrophone} from 'react-icons/fa'

const PlayBtnCont = styled.li`
	background: #c7a4c43b;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 20px;
    cursor: pointer;
    min-width: 40px; 
    height: 40px;
	box-shadow: 1px 1px 3px rgba(13,3,15, 0.7);
`
const PlayBtn = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	color: #b595b2;

    ${PlayBtnCont}:hover & {
		color: #fff8c2;
	}
`

export default ({ setPlayerPause, playerPause, url, http_url, browser, audioStream, nimfm_url}) => {

    const [oldBrowser, setOldBrowser] = useState(false)
    const [intro, setIntro] = useState(true)

    const lastUrl = useRef('')

    let stream = audioStream

    const Stream = () => (                                    
    <audio autoPlay>
        <source src={http_url} type="audio/mpeg" />
        Your browser does not support the <code>audio</code> element.
    </audio> )

    let playPause = e => {
        e.preventDefault()
        setPlayerPause(state => ({pauseplay:!state, btn:true}))
    }

    let pausePlay = useCallback(() => {
        
        if(url && !oldBrowser){

            if(!lastUrl.current){
                //console.log('url changed in pausePlay stream play pause true')
                stream.load()                
                stream.paused && stream.play()
                setPlayerPause({pauseplay:true, btn:false})                
            }
            else if(stream.ended){
                setPlayerPause({pauseplay:false, btn:false})
            }
            else if(stream.paused){
                //console.log('load and play in pausePlay', stream.src, stream.paused)
                url === nimfm_url && stream.load()
                stream.play()
                setPlayerPause({pauseplay:true, btn:false})
            }
            else{
                //console.log('else pause in pausePlay', stream.src, stream.paused)
                stream.pause()
                setPlayerPause({pauseplay:false, btn:false})
            }
            
            lastUrl.current = url
        }
    }, [oldBrowser, stream, url, setPlayerPause, nimfm_url])

    useEffect(() => {
        //console.log('url changed in url effect in player', url)
        lastUrl.current = ''
        stream.src = url
        pausePlay()
    }, [url, stream, pausePlay])

    useEffect(() => {
        //console.log('playerPause called from Player effect')
        if(intro && playerPause.pauseplay) setIntro(false)
        playerPause.btn && pausePlay()
    }, [playerPause, pausePlay, intro])

    useEffect(() => {
        if((browser.name === 'Safari' && browser.majorVersion < 10 )
        || (browser.name === 'Chrome' && browser.majorVersion < 80 )
        || (browser.name === 'Firefox' && browser.majorVersion < 4)){
            setOldBrowser(true)
        }
    }, [browser])

    useEffect(() => {
        stream.addEventListener("ended", () => {
            setPlayerPause(state => ({pauseplay:false, btn:false}))
        })
    }, [stream, setPlayerPause])

    return <>
            {!intro
            ?   <PlayBtnCont onClick={playPause}>
                {
                    playerPause.pauseplay
                    ?   <PlayBtn>
                            <FaPause size={25} color={'#FFF8C2'} />
                            {oldBrowser && <Stream />}
                        </PlayBtn>
                    :   <PlayBtn style={{marginLeft:'4px'}}>
                            <FaPlay size={25}  />
                        </PlayBtn>
                }
                </PlayBtnCont>
            : <FaMicrophone size={40} color={'#fff8c2'} />
            }
        </>
    
}



