import styled from 'styled-components'
import media from './media'

export const Main = styled.div`
    position:relative;
    display:flex;
    width:100%;
    align-items:center;
    flex-flow:column;
    background: linear-gradient(0deg, rgba(13,13,13,1) 8%, rgba(13,13,13,0) 20%) center center;
    overflow:hidden;

    ${media.phone`background: linear-gradient(0deg, rgba(13,13,13,1) 0%, rgba(13,13,13,0) 5%) center center;`}
` 
export const BodyFour = styled.div`
    max-width:1000px;
    min-width:260px;
    padding: 90px 30px 30px;
` 
export const Spirits = styled.img`
    width:100%;
    margin-bottom:100px;
    ${media.phone`width:120%; margin-bottom:20px;`}
` 
export const HeaderOne = styled.div`
    font-size:2rem;
    line-height: 1.2;
    text-align: center;

    ${media.phone`font-size:1.5rem;`}
` 
export const BtmBtns = styled.div`
    display:flex;
    justify-content:space-around;
    align-items:center;
    width:100%;
    margin-bottom:60px;
    font-family:Arial, Helvetica, sans-serif;

    ${media.phone`margin-bottom:90px;`}

`
export const Facebook = styled.a`
    display:flex;
    align-items:center;
    cursor: pointer;
`
export const AudioLink = styled.a`
    display:flex;
    align-items:center;
    cursor: pointer;
`